.App {
  text-align: center;
  font-family: 'Encode Sans', sans-serif;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(20px + 2vmin);
  color:rgb(8, 0, 0); 
}

.App-link {
  color: #61dafb;
}

body.label{
  font-family: 'Open Sans',Helvetica,Arial,sans-serif;
  font-size: 14px;
}

.activo{
  background-color: #00b1c7;
  color: white;
}

.retenidoInhabilitado{
  background-color: #00b1c7 !important;
  color: white !important;
}




.zoom-page:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}